<template lang="pug">
v-flex
  v-overlay(v-if="loading")
    v-progress-circular(indeterminate)
  v-col(cols="12")
    h2 {{mapRef}} {{ point.name }}
  transition-group.col-12.m-0.p-0(tag="div" name="fade" mode="out-in")
    // Kit sections
    v-col(cols="12" :key="0" v-if="viewSelector && showOptions")
      v-col(v-for="(kit, i) in kits" :key="i")
        v-select(:label="$t('label.model')" :items="models" item-text="model" item-value="model" v-model="kit.model")
        v-row
          v-col
            v-text-field(type="datetime-local" v-model="kit.segment[0]")
          v-col
            v-text-field(type="datetime-local" v-model="kit.segment[1]")
        v-divider
      // Kit sections
      v-col.text-right(cols="12")
        v-btn(color="primary" x-small v-show="viewSelector && showOptions" @click="generateData()")
          v-icon(x-msall) mdi-play
    v-col(cols="12" :key="1" v-if="!loading && model_generated.length > 0")
      v-col.text-right.py-0.my-0(v-if="viewSelector" cols="12")
        v-btn(x-small @click="showOptions = !showOptions")
          template(v-if="showOptions")
            | Hide
            v-icon mdi-chevron-up
          template(v-else)
            | Show
            v-icon mdi-chevron-down
      v-col.py-0.my-0(cols="12" v-if="error")
        v-alert(color="red") {{ error.msg }}
      v-col.py-0.my-0(cols="12" v-if="copy")
        v-alert.white--text(dense small color="green")
          v-row
            v-col  {{ $t("msg.copyFrom") }} {{ copy.name }}
            v-col.text-right
              v-btn(x-small @click="reinit()") {{ $t("button.reset") }}
      // Copie from
      v-col(cols="12" v-show="!viewSelector")
        v-select(:label="$t('label.copyFrom')" v-model="copy" :items="points" @change="copyFrom()" return-object item-text="name")
        v-divider
      // Copie from
      v-col(cols="12")
        v-row
          v-col(cols="12")
            highcharts(ref="hightchart" :constructor-type="'stockChart'" :options="chartOptions")
          // Modifier
          v-col(cols="12" v-if="!viewSelector")
            v-card
              v-card-text
                v-row
                  v-col.py-0.my-0(cols="12")
                    v-checkbox(:label="$t('label.correctionWithPercent?')" v-model="isPercent")
                  v-col.py-0.my-0
                    v-select(:items="['tair', 'tdew', 'rh', 'cloud', 'w_max', 'w_avg']" v-model="selectedModifier" :label="$t('label.correctionInPercent?')")
                  v-col.py-0.my-0
                    v-text-field(type="number" v-model="modifiers[selectedModifier]" :append-icon="isPercent ? 'mdi-percent' : ''")
                  v-col.text-right(cols="12")
                    v-btn(@click="generateData" color="success" small)
                      | {{ $t("button.apply") }}
      v-col(v-if="!viewSelector")
        v-row
          v-col.text-left
            v-btn(block @click="reinit" small color="warning")
              | {{ $t("button.reinit") }}
          v-col.text-center
            v-btn(color="info" block small @click="applyToAllPoint()")
              | {{ $t("button.applyToAllPoints") }}
          v-col.text-right
            v-btn(color="primary" small block @click="buildModelForExport")
              | {{ $t("button.save") }}
    v-col.text-center(cols="12" v-else-if="loading && !model_generated" :key="2")
      v-progress-circular(:width="7" color="primary" indeterminate)
      br
      | {{ $t("loading.dataLoading") }}
    v-col(cols="12" v-else :key="3")
      h3 {{ $t("loading.pleaseStartModels") }}
  v-snackbar(v-model="error" top color="error") {{$t('error.errorProduceTryAgain')}}
  v-snackbar(v-model="success" top color="success") {{$t('success.sendSuccess')}}
</template>

<script>
import api from '@/api'
import moment from 'moment-timezone'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import { mapState } from 'vuex'
import EventBus from '@/plugins/event-bus'

import draggablePoints from 'highcharts/modules/draggable-points'
import _ from 'lodash'
stockInit(Highcharts)
draggablePoints(Highcharts)

Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true
  }
  return false
}

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element)
  }
}

export default {
  props: ['point', 'points', 'viewSelector', 'standalone', 'mapRef'],
  data() {
    return {
      extremes: null,
      models: [],
      model_generated: false,
      copy: null,
      showOptions: true,
      loading: true,
      error: false,
      success:false,
      modelexport: [],
      changeList: [],
      isPercent: false,
      editable: true,
      selectedModifier: 'tair',
      modifiers: {
        tair: 0,
        tdew: 0,
        rh: 0,
        w_max: 0,
        cloud: 0,
        w_avg: 0,
        rain: 0,
        snow: 0,
      },
      kits: [],
    }
  },
  computed: {
    ...mapState(['map_kits', 'common_params']),
    liveChange() {
      return this.changeList
    },
    chartOptions() {
      let tmin = 100
      let tmax = -100
      let rmax = -100
      let smax = -100

      let series = []
      series['tair'] = []
      series['tdew'] = []
      series['cloud'] = []
      series['rain'] = []
      series['snow'] = []
      series['w_max'] = []
      series['w_avg'] = []
      let dts = []
      if (this.model_generated) {
        this.model_generated.forEach((element) => {
          element.forEach((data) => {
            if (!dts.includes(data.dt)) {
              dts.push(data.dt)
              this.modelexport.push(data)
              // tempmin max
              if (data.tair < tmin) {
                tmin = data.tair
              }
              if (data.tair > tmax) {
                tmax = data.tair
              }
              if (data.tdew < tmin) {
                tmin = data.tdew
              }
              if (data.tdew > tmax) {
                tmax = data.tdew
              }
              if (data.rain > rmax) {
                rmax = (data.rain + 5)
              }
              if (data.snow > smax) {
                smax = data.snow
              }
              series['tair'].push([moment(data.dt).unix() * 1000, data.tair])
              series['tdew'].push([moment(data.dt).unix() * 1000, data.tdew])
              series['cloud'].push([moment(data.dt).unix() * 1000, data.cloud])
              series['rain'].push([moment(data.dt).unix() * 1000, data.rain])
              series['snow'].push([moment(data.dt).unix() * 1000, data.snow])
              series['w_max'].push([moment(data.dt).unix() * 1000, data.w_max])
              series['w_avg'].push([moment(data.dt).unix() * 1000, data.w_avg])
            }
          })
        })
      }
      return {
        rangeSelector: {
          selected: 48,
          inputEnabled: false,
          enabled: false,
        },
        legend:{
          enabled:  true
        },
        zoomType: false,
        chart:{
          height:'400px',
          events: {
                load: ()=> {
                  if(this.extremes){
                    setTimeout(()=>{
                      this.$refs.hightchart.chart.xAxis[0].setExtremes(this.extremes.min, this.extremes.max)
                    },400)
                  }
                }
            }
        },
        xAxis: {
          max: this.model_generated[0][0]?this.model_generated[0][0].time * 1000 + 48 * 3600 * 1000:0,
          range: 48 * 3600 * 1000,
          plotLines: [
            {
              color: '#D3D3D3',
              width: 2,
              value: this.model_generated[0][this.model_generated[0].length - 1]
                ? moment(
                    this.model_generated[0][this.model_generated[0].length - 1]
                      .dt
                  ).unix() * 1000
                : 0,
            },
          ],
        },
        yAxis: [
          {
            labels: {
              enabled: true,
            },
            min: tmin,
            max: tmax,
          },
          {
            labels: {
              enabled: false,
            },
            min: 0,
            max: 100,
            opposite: true,
          },
          {
            labels: {
              enabled: false,
            },
            min: 0,
            max: Math.max(10, rmax + 2),
          },
          {
            labels: {
              enabled: false,
            },
            min: 0,
            max: smax + 4,
          },
        ],
        plotOptions: {
          series: {
            dataLabels:{
              enabled:false,
            },
            point: {
              events: {
                drop: (e) => this.changeStack(e),
              },
            },
            stickyTracking: false,
          },
          line: {
            cursor: this.editable ? 'ns-resize' : '',
          },
        },
        tooltip: {
          shape: 'square',
          headerShape: 'callout',
          borderWidth: 0,
          shadow: false,
          xDateFormat: '%d/%m %H:%M',
          positioner: function (width, height, point) {
            var chart = this.chart,
              position

            if (point.isHeader) {
              position = {
                x: Math.max(
                  // Left side limit
                  chart.plotLeft,
                  Math.min(
                    point.plotX + chart.plotLeft - width / 2,
                    // Right side limit
                    chart.chartWidth - width - chart.marginRight
                  )
                ),
                y: point.plotY,
              }
            } else {
              position = {
                x: point.series.chart.plotLeft,
                y: point.series.yAxis.top - chart.plotTop,
              }
            }

            return position
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 800,
              },
              chartOptions: {
                rangeSelector: {
                  inputEnabled: false,
                  enabled: false,
                },
              },
            },
          ],
        },
        series: [
          {
            type: 'line',
            id: 'tair',
            color: '#f63939',
            name: this.$t('tair'),
            data: series['tair'],
            tooltip: {
              valueSuffix: '°C',
            },
            yAxis: 0,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
            maxY: 40,
            minY: -40,
          },
          {
            type: 'line',
            id: 'tdew',
            color: '#c65eff',
            name: this.$t('tdew'),
            showInNavigator: true,
            navigatorOptions: {
              type: 'line',
            },
            data: series['tdew'],
            tooltip: {
              valueSuffix: '°C',
            },
            yAxis: 0,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
            maxY: 40,
            minY: -40,
          },
          {
            type: 'line',
            id: 'rh',
            color: '#acbd77',
            name: this.$t('rh'),
            showInNavigator: true,
            navigatorOptions: {
              type: 'line',
            },
            data: series['rh'],
            tooltip: {
              valueSuffix: '%',
            },
            yAxis: 1,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
            maxY: 100,
            minY: 0,
          },
          {
            type: 'line',
            id: 'cloud',
            color: '#535353',
            name: this.$t('cloud'),
            showInNavigator: true,
            navigatorOptions: {
              type: 'line',
            },
            data: series['cloud'],
            tooltip: {
              valueSuffix: '%',
            },
            yAxis: 1,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
            maxY: 100,
          },
          {
            type: 'line',
            id: 'w_max',
            color: '#5e86ff',
            name: this.$t('w_max'),
            showInNavigator: true,
            navigatorOptions: {
              type: 'line',
            },
            data: series['w_max'],
            tooltip: {
              valueSuffix: 'km/h',
            },
            yAxis: 1,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
          },
          {
            type: 'line',
            id: 'w_avg',
            color: '#001f7f',
            name: this.$t('w_avg'),
            showInNavigator: true,
            navigatorOptions: {
              type: 'line',
            },
            data: series['w_avg'],
            tooltip: {
              valueSuffix: 'km/h',
            },
            yAxis: 1,
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
          },

          {
            type: 'column',
            id: 'rain',
            color: '#68a7dc',
            minPointLength: 2,
            name: this.$t('rain'),
            navigatorOptions: {
              type: 'bar',
            },
            data: series['rain'],
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.1,
            },
            tooltip: {
              valueSuffix: 'mm',
            },
            yAxis: 3,
            minY: 0,
            maxY: rmax,
          },
          {
            type: 'column',
            id: 'snow',
            color: '#999999',
            minPointLength: 2,
            name: this.$t('snow'),
            navigatorOptions: {
              type: 'bar',
            },
            dragDrop: {
              draggableY: this.editable,
              dragPrecisionY: 0.5,
            },
            yAxis: 3,
            minY: 0,
            maxY:smax,
            data: series['snow'],
            tooltip: {
              valueSuffix: 'cm',
            },
          },
        ],
      }
    },
  },
  watch: {
    map_kits: {
      deep: true,
      async handler() {
        if (!this.standalone) {
          if (!this.loading) {
            this.kits = this.map_kits
            await this.getModels()
            this.reinit()
          }
        }
      },
    },
    common_params: {
      deep: true,
      async handler() {
        if (!this.standalone) {
         this.applyChange()
        }
      },
    }
  },
  async mounted() {
    await this.addKit()
    await this.getModels()
    EventBus.$on('applyToAll', async (payLoad) => {
      if (!this.standalone) {
        if (!this.loading) {
          this.kits = payLoad
          await this.getModels()
          this.reinit()
        }
      }
    })
    EventBus.$on('saveAll', async () => {
      this.buildModelForExport()
    })
  },
  methods: {
    valueModifier(initialValue, modifier) {
      let value = null
      if (this.isPercent) {
        // Mode pourcent
        value = Math.round(
          (initialValue +
          (parseInt(this.modifiers[modifier]) / 100) * Math.abs(initialValue))
        )
        if(modifier == 'cloud' && value > 100){
          value = 100
        }else if(modifier == 'cloud' && value < 0){
          value = 0
        }
        return value
      } else {
        // Mode normal
        value = Math.round(initialValue + parseInt(this.modifiers[modifier]))
        if(modifier == 'cloud' && value > 100 || modifier == 'cloud' && value < 0){
          value = 100
        }else if(modifier == 'cloud' && value < 0){
          value = 0
        }
        return value
      }
    },
    async getModels(id = null) {
      this.loading = true
      if (!id) id = this.point.id
      let models = []
      try{
      let runs = await api.atmocast.get_models_point(id)
      if (runs) {
        runs.atmocasts.forEach((element) => {
          models.push(element)
        })
        runs.publicasts = _.orderBy(runs.publicasts, 'id', 'desc')
        runs.publicasts.forEach((element, index) => {
          if (index === 0) {
            element.name = `Dernière publication (${moment(
              element.time_min * 1000
            ).format('DD/MM/YYYY HH:mm')})`
            element.model = 'publicast-last'
          } else {
            element.model = 'publicast-before-last'
            element.name = `Avant dernière publication (${moment(
              element.time_min * 1000
            ).format('DD/MM/YYYY HH:mm')})`
          }
          models.push(element)
        })
        this.models = models
        this.loading = false
      }
      }catch(err){
        this.laoding = false
        this.error = true
      }
    },
    addKit(model = null, segment = [moment().format('YYYY-MM-DD[T]HH:mm'), moment().add(72, 'hours').format('YYYY-MM-DD[T]HH:mm')]) {
      if (this.kits.length <= 2) {
        this.kits.push({
          model: model,
          duration: '0',
          max: segment[1],
          segment: segment,
        })
      }
    },
    async copyFrom() {
      this.model_generated = null
      await this.getModels(this.copy.id)
      this.generateData()
    },
    async reinit() {
      this.copy = false
      this.changeList = []
      this.modifiers = {
         tair: 0,
          tdew: 0,
          rh: 0,
          w_max: 0,
          cloud: 0,
          w_avg: 0,
          rain: 0,
          snow: 0,
      }
      await this.getModels()
      this.generateData()
    },
    applyToAllPoint() {
      this.$store.dispatch('setKits', this.kits)
      if (this.changeList.length > 0) {
        this.$store.dispatch('setCommonParams', this.changeList)
      }
    },
    applyChange(){
       this.changeList.forEach((params) => {
            let name = params.id
            this.model_generated[0].map((model) => {
              if (moment(model.dt).unix() * 1000 == params.x) {
                model[name] = params.y
              }
            })
          })
    },
    async buildModelForExport() {
      this.loading = true
      this.applyChange()
      let dts = []
      this.modelexport = []
      this.model_generated.forEach((element) => {
        element.forEach((data) => {
          if (!dts.includes(data.dt)) {
            dts.push(data.dt)
            this.modelexport.push(data)
          }
        })
      })
      try{
        await api.atmocast.publish(this.point.id, this.modelexport)
        this.loading = false
        this.success = true
      }catch(err){
        this.loading = false
        this.error = err
      }
      this.getModels()
    },
    changeStack(e) {
      let name = e.target.series.id
      let options = e.target.options
      let content = {
        name: name,
        ...options,
      }
      let find = false

      for (var i = 0; i < this.changeList.length; i++) {
        if (
          this.changeList[i].name == content.name &&
          this.changeList[i].x == content.x
        ) {
          find = true
          this.changeList[i] = content
        }
      }
      if (!find) {
        this.changeList.push(content)
      }
    },
    async generateData() {
      this.model_generated = []
      if (this.$refs.hightchart) {
        this.extremes = this.$refs.hightchart.chart.xAxis[0].getExtremes()
      }
      this.loading = true
      try{
        for (var i = 0; i < this.kits.length; i++) {
        //   this.kits.forEach(async (element) => {
        let modelSelected = this.models.find(
          (map) => map.model == this.kits[i].model
        )
        let atmo_data = []
        if (modelSelected.model.indexOf('publicast') > -1) {
          atmo_data = await api.publicast.get_data(modelSelected.id)
        } else {
          atmo_data = await api.atmocast.get_data(modelSelected.id)
        }
        atmo_data.map((map) => {
          map.model = this.kits[i].model
        })
        let datas = []
        atmo_data.forEach((map) => {
          if(moment(map.dt).unix() >= moment(this.kits[i].segment[0]).unix() && moment(map.dt).unix() <= moment(this.kits[i].segment[1]).unix())
          {
            if (this.extremes) {
              if ( moment(map.dt) >= this.extremes.min && moment(map.dt) <= this.extremes.max) {
                map.tair = this.valueModifier(map.tair, 'tair')
                map.tdew = this.valueModifier(map.tdew, 'tdew')
                map.rh = this.valueModifier(map.rh, 'rh')
                map.w_max = this.valueModifier(map.w_max, 'w_max')
                map.w_avg = this.valueModifier(map.w_avg, 'w_avg')
                map.cloud = this.valueModifier(map.cloud, 'cloud')
                map.rain = this.valueModifier(map.rain, 'rain')
                map.snow = this.valueModifier(map.snow, 'snow')
              }
            }
            datas.push(map)
          }
        })
        this.model_generated.push(datas)
      }
      } catch(err) {
        this.loading=false
      }
      this.loading = false
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
